import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Tabs, Card, Tag, Button, message, Modal } from 'antd';

import ConnectLoading from '../../../components/ConnectLoading';
import { setBreadcrumb } from '../../../actions/commonActions';
import { getUserInfo, activateUser, deactivateUser } from '../actions/userActions';
import DetailsTable from '../../../components/DetailsTable';
import EditUserModal from './EditUserModal';
import {USER_DEACTIVATE_FILTER } from '../../../../src/views/organisations/constants';
import { USER_STATUS } from '../Constants';

const TabPane = Tabs.TabPane;
const confirm = Modal.confirm;

class UserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: undefined,
            isEditUsermodalVisible: false
        }
        this.loadUser();

    }

    loadUser = async () => {
        const userInfo = await getUserInfo(this.props.userId);
        this.setState({ userInfo });
        const name = `${userInfo.first_name} ${userInfo.last_name}`;
        this.props.setBreadcrumb(name, [
            { route: '/users', label: 'Users' },
            { route: '', label: name }
        ]);
    }

    activateUser = async() =>{
        const user = this.state.userInfo;
        message.loading('Activating user...', 0);
        await activateUser(user.user_id, user.email);
        message.destroy();
        message.success("Successfully activated user");
        this.loadUser();
    }
    deactivateUser = async() =>{
        const user = this.state.userInfo;

        const handleDeactivate = async () => {
            message.loading('Deactivating user...', 0);
            await deactivateUser(user.user_id, user.email);
            message.destroy();
            message.success("Successfully deactivated user");
            this.loadUser();
        };

        if (user.role === USER_DEACTIVATE_FILTER) {
            confirm({
                title: 'Are you sure you want to deactivate the Contractor Admin User? Deactivating this User will permanently disable their access and  will no longer be able to log in.',
                okText: 'Yes',
                cancelText: 'No',
                onOk: handleDeactivate,
            })
        } else {
            handleDeactivate();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    userDetailsView = () => {
        const userInfo = this.state.userInfo;
        const isActiveuser = userInfo.status === 'ACTIVE';
        let fields = [
            { key: "First Name", value: userInfo.first_name },
            { key: "Last Name", value: userInfo.last_name },
            { key: "Email", value: userInfo.email },
            { key: "Job Role", value: userInfo.role },
            { key: "Status", value: <Tag color={isActiveuser ? 'green' : 'red'}>{userInfo.status}</Tag> },
        ];

        return (
            <div className="pure-g">
                {this.state.isEditUsermodalVisible &&
                    <EditUserModal
                        visible={this.state.isEditUsermodalVisible}
                        user = {this.state.userInfo}
                        onCancel={() => this.setState({ isEditUsermodalVisible: false })}
                        onSave={() => {
                            this.loadUser();
                            this.setState({ isEditUsermodalVisible: false })
                        }}
                    />
                }
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        <div className="pure-u-1">
                            {userInfo.isAdmin !== USER_STATUS.ADMIN && isActiveuser && <Button className='epar__tasks__action--button' type='danger' icon="pause-circle" onClick={this.deactivateUser}>Deactivate</Button>}
                            {!isActiveuser && <Button className='epar__tasks__action--button' type='danger' icon="check-circle"  onClick={this.activateUser}>Activate</Button>}
                            <Button icon="edit" className='epar__tasks__action--button' onClick={() => this.setState({ isEditUsermodalVisible: true })}>Edit User Details</Button>
                        </div>
                    </div>
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (!this.state.userInfo) {
            return <ConnectLoading />
        }
        return (
            <Card>
                <Tabs className='epar__details__view'>
                    <TabPane tab="User Details" key="0">
                        {this.userDetailsView()}
                    </TabPane>
                    <TabPane tab="Organisations" key="1">
                    </TabPane>
                    <TabPane tab="Records" key="2">
                    </TabPane>
                </Tabs>
            </Card>
        )

    }
}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserView);